/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React, { useState } from "react";
import Roles from "./components/Roles";
import Permissions from "./components/Permissions";

const RolesAndPermissions: React.FC = () => {
  const [tab, setTab] = useState("Roles");
  const [savingLoading, setSavingLoading] = useState<boolean>(false);

  const saveChanges = () => {
    setSavingLoading(true);
    try {
      setTimeout(() => {
        setSavingLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      setSavingLoading(false);
    }
  };

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <h1 className="fw-bold mb-1 display-6">Roles and Permissions</h1>
        <span className="text-muted mt-1 fw-semibold fs-7">
          Add or remove roles, and permissions
        </span>
      </div>
      <div className="card card-custom">
        <div className="card-header card-header-stretch overflow-auto">
          <ul
            className="nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          "
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Roles"
                })}
                onClick={() => setTab("Roles")}
                role="tab"
              >
                Roles
              </a>
            </li>
            <li className="nav-item">
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Permissions"
                })}
                onClick={() => setTab("Permissions")}
                role="tab"
              >
                Permissions
              </a>
            </li>
          </ul>
        </div>

        <form className="form">
          <div className="card-body">
            <div className="tab-content pt-3">
              <Roles tab={tab} />
              <Permissions tab={tab} />
            </div>
            
            <div className="card-footer py-6 px-0 text-end">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={saveChanges}
                    className="btn btn-primary"
                  >
                    {!savingLoading && (
                      <span className="indicator-label">Save Changes</span>
                    )}
                    {savingLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export { RolesAndPermissions };
