import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";

import Form from "../../../layout/components/form/Form";
import {
  MultiSelectDropdownWithInput,
  SingleSelectDropdown
} from "../../../layout/components/form/FormFields";

import InsSpinner from "../../../layout/components/spinner/InsSpinner";

import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import PageHeader from "../../../layout/components/content/PageHeader";

export default function MktoLifecycleStages() {
  const [initialValues, setInitialValues] = useState(undefined);
  const [stringFields, setStringFields] = useState<any>([]);
  const [mktoDataFetchStatus, setMktoDataFetchStatus] =
    useState<any>(undefined);
  const intl = useIntl();

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    setSubmitting(true);

    const lifecycleStages = {
      stages: Object.keys(values).reduce((acc: any, key) => {
        if (key !== "lead_field") {
          acc[key] = values[key].map((value: any) => value.value);
        }
        return acc;
      }, {}),
      field: values.lead_field
    };

    try {
      await axios.post("/configs/insentric-mkto", {
        lifecycle_stages: lifecycleStages
      });

      toast.success(
        intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }),
        toastSettings("success")
      );
    } catch (err: any) {
      toast.error(
        `${intl.formatMessage({
          id: "SETTINGS.TOAST.ERROR"
        })}: ${err.message}`,
        toastSettings("error")
      );
    }
  };

  useEffect(() => {
    setMktoDataFetchStatus(undefined);
    Promise.all([
      axios.get("/status/data"),
      axios.get("/marketo/settings/marketo_fields/lead", {
        params: {
          data_types: ["string"]
        }
      }),
      axios.get("/configs/insentric-mkto") // existing call
    ])
      .then((response) => {
        const [status, stringFieldsResponse, lifecycleStagesResponse] =
          response;

        if (status?.data?.mkto?.lead_fields.last_successful_fetch !== "") {
          setMktoDataFetchStatus(true);
        } else {
          setMktoDataFetchStatus(false);
        }

        // Set data for stringFields
        setStringFields(
          stringFieldsResponse.data.map((stringField: any) => ({
            ...stringField,
            label: stringField.displayName,
            value: stringField.name
          }))
        );

        // Existing logic to set initial values
        const stages = lifecycleStagesResponse?.data?.lifecycle_stages?.stages;
        const field = lifecycleStagesResponse?.data?.lifecycle_stages?.field;

        setInitialValues({
          ...Object.keys(stages).reduce((acc: any, stage) => {
            acc[stage] = stages[stage].map((value: any) => ({
              value: value,
              label: value
            }));
            return acc;
          }, {}),
          lead_field: field
        });
      })
      .catch((error) => {
        console.error("Error fetching initial values:", error);
      });
  }, []);

  const emptySchema = Yup.object().shape({});

  const fields = [
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.LEADFIELD" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.LEADFIELD.DESC"
      }),
      id: "lead_field",
      options: stringFields,
      fieldComponent: (props: any) => (
        <SingleSelectDropdown
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.KNOWN" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.KNOWN.DESC"
      }),
      id: "1_known",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.ENGAGED" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.ENGAGED.DESC"
      }),
      id: "2_engaged",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.MQL" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.MQL.DESC"
      }),
      id: "3_mql",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.SAL" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.SAL.DESC"
      }),
      id: "4_sal",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.SQL" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.SQL.DESC"
      }),
      id: "5_sql",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.OPPORTUNITY"
      }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.OPPORTUNITY.DESC"
      }),
      id: "6_opportunity",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.CLOSEDWON" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.CLOSEDWON.DESC"
      }),
      id: "7_closed_won",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.CLOSEDLOST"
      }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.CLOSEDLOST.DESC"
      }),
      id: "8_closed_lost",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.RECYCLE" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.RECYCLE.DESC"
      }),
      id: "9_recycle",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.DISQUALIFIED"
      }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.DISQUALIFIED.DESC"
      }),
      id: "10_disqualified",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.MKTOLLSTAGES.TRASH" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.TRASH.DESC"
      }),
      id: "11_trash",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },
    {
      label: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.REVENUEREALIZED"
      }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.MKTOLLSTAGES.REVENUEREALIZED.DESC"
      }),
      id: "12_revenue_realized",
      fieldComponent: (props: any) => (
        <MultiSelectDropdownWithInput
          {...props}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    }
  ];

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MARKETO"
          level1link="/app/home"
          level2name="SETTINGS.MKTO.MKTOLLSTAGES"
          instructions="SETTINGS.MKTO.MKTOLLSTAGES.INSTRUCTIONS"
        />
      </div>
      <div className="card px-3 mb-xl-3">
        {mktoDataFetchStatus === undefined ? (
          <div>
            <InsSpinner />
          </div>
        ) : mktoDataFetchStatus ? (
          initialValues ? (
            <div className="mt-10 mb-10">
              <Container fluid>
                <div>
                  <Form
                    fields={fields}
                    initialValues={initialValues}
                    validationSchema={emptySchema}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </Container>
            </div>
          ) : (
            <div>
              <InsSpinner />
            </div>
          )
        ) : (
          <div className="mt-10 mb-10">
            <Container fluid>
              <div className="d-flex justify-content-center">
                <h3>
                  {intl.formatMessage({
                    id: "SETTINGS.MKTO.GENERAL.DATAFETCHING.WAITMESSAGE"
                  })}
                </h3>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
