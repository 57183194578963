import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSync } from "@fortawesome/free-solid-svg-icons";
import { Form as BootstrapForm } from "react-bootstrap";
import { Field, ErrorMessage, useField } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import ja from "date-fns/locale/ja";
import zhCN from "date-fns/locale/zh-CN";
import Slider from "rc-slider";
import { handleRender } from "../../../helpers/components/handleRender";
import "rc-slider/assets/index.css";

import { toast } from "react-toastify";
import { toastSettings } from "../toast/Toast";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

// Register locales
registerLocale("en", enUS);
registerLocale("fr", fr);
registerLocale("es", es);
registerLocale("de", de);
registerLocale("ja", ja);
registerLocale("zh", zhCN);

const fetchAndHandle = async (url, setIsRefreshing, intl, body) => {
  try {
    await axios.post(url, body);
    toast.success(
      intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.SUCCESS" }),
      toastSettings("success")
    );
    toast.success(
      intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.SUCCESS" }),
      toastSettings("success")
    );
  } catch (err) {
    toast.error(
      `${intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.ERROR"
      })}: ${err.message}`,
      toastSettings("error")
    );
    toast.error(
      `${intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.REFRESHVALUES.ERROR"
      })}: ${err.message}`,
      toastSettings("error")
    );
  } finally {
    setIsRefreshing(false); // Ensure the spinner is stopped
  }
};

const handleRefreshClick = async (refreshType, setIsRefreshing, intl) => {
  setIsRefreshing(true); // Start the spinner

  try {
    switch (refreshType) {
      case "smartlist":
        await fetchAndHandle(
          "/marketo/fetch/assets",
          setIsRefreshing,
          intl,
          { assetNames : ["smart_lists"]}
        );
        break;
      case "lead":
        // Refresh logic for lead
        break;
      case "fields":
        // Refresh logic for fields
        break;
      case "programs":
        await fetchAndHandle("/marketo/fetch/programs", setIsRefreshing, intl);
        break;
      default:
        // No action needed for default case
        break;
    }
  } catch (error) {
    console.error("Error during refresh: ", error);
    // Optionally handle the error here instead
  } finally {
    setIsRefreshing(false); // Stop the spinner
  }
};

const requireRefreshType = (props, propName, componentName) => {
  if (props.showRefreshButton && !props[propName]) {
    return new Error(
      `${propName} is required when showRefreshButton is true in ${componentName}`
    );
  }
  return null;
};
export const TextField = ({
  id,
  label,
  isInvalid = false,
  description,
  optional = false,
  disabled = false,
  classes,
  placeholder,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>
          {`${label}${optional ? " (optional)" : ""}`}
        </BootstrapForm.Label>
      )}
      <Field
        type="text"
        id={id}
        name={id}
        as={BootstrapForm.Control}
        isInvalid={isInvalid}
        className={classes ? `form-input ${classes}` : "form-input"}
        disabled={disabled}
        placeholder={placeholder}
        aria-labelledby={
          ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
        }
        aria-describedby={
          ariaDescribedBy || (description ? `${id}-description` : undefined)
        }
        {...props}
      />
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  isInvalid: PropTypes.bool,
  description: PropTypes.string,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const NumberField = ({
  id,
  label,
  description,
  optional = false,
  disabled = false,
  classes,
  min,
  max,
  placeholder,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>
          {`${label}${optional ? " (optional)" : ""}`}
        </BootstrapForm.Label>
      )}
      <Field
        type="number"
        min={min}
        max={max}
        id={id}
        name={id}
        as={BootstrapForm.Control}
        className={`form-input ${classes}`}
        disabled={disabled}
        placeholder={placeholder}
        aria-labelledby={
          ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
        }
        aria-describedby={
          ariaDescribedBy || (description ? `${id}-description` : undefined)
        }
        {...props}
      />
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

NumberField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  placeholder: PropTypes.number,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const TextAreaField = ({
  id,
  label,
  isInvalid = false,
  description,
  optional = false,
  disabled = false,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  return (
    <BootstrapForm.Group controlId={id} className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>
          {`${label}${optional ? " (optional)" : ""}`}
        </BootstrapForm.Label>
      )}
      <Field
        as="textarea"
        rows="5"
        id={id}
        name={id}
        isInvalid={isInvalid}
        className={`form-input ${classes}`}
        disabled={disabled}
        aria-labelledby={
          ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
        }
        aria-describedby={
          ariaDescribedBy || (description ? `${id}-description` : undefined)
        }
        {...props}
      />
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

TextAreaField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  description: PropTypes.string,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const SwitchField = ({
  id,
  label,
  isInvalid = false,
  description,
  optional = false,
  disabled = false,
  checked = true,
  onChange,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  return (
    <BootstrapForm.Group className="mb-3 py-6">
      <div style={{ display: "flex", alignItems: "center" }}>
        <BootstrapForm.Check
          type="switch"
          id={id}
          name={id}
          isInvalid={isInvalid}
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : undefined)
          }
          {...props}
        />
        {label && (
          <BootstrapForm.Label
            htmlFor={id}
            className={hideLabel ? "sr-only" : ""}
            style={{ marginLeft: "0.75em" }}
            id={`${id}-label`}
          >
            {`${label}${optional ? " (optional)" : ""}`}
          </BootstrapForm.Label>
        )}
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

SwitchField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  description: PropTypes.string,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const PasswordField = ({
  id,
  label,
  isInvalid = false,
  optional = false,
  description,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  disabled = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>
          {`${label}${optional ? " (optional)" : ""}`}
        </BootstrapForm.Label>
      )}
      <div
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <Field
          type={showPassword ? "text" : "password"}
          id={id}
          name={id}
          as={BootstrapForm.Control}
          isInvalid={isInvalid}
          className={`form-input ${classes}`}
          disabled={disabled}
          style={{ paddingRight: "30px" }}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : undefined)
          }
          {...props}
        />
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer"
          }}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

PasswordField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  optional: PropTypes.bool,
  disabled : PropTypes.bool,
  description: PropTypes.string,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const SelectField = ({
  id,
  label,
  isInvalid = false,
  options = [],
  description,
  disabled,
  classes,
  placeholder,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>
      )}
      <div className="flex-help">
        <Field
          as="select"
          id={id}
          name={id}
          className={`form-input ${classes}`}
          isInvalid={isInvalid}
          disabled={disabled}
          placeholder={placeholder}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : undefined)
          }
          {...props}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={id}
          component={BootstrapForm.Control.Feedback}
          type="invalid"
        />
        {!hideDescription && description && (
          <BootstrapForm.Text id={`${id}-description`} muted>
            {description}
          </BootstrapForm.Text>
        )}
      </div>
    </BootstrapForm.Group>
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  description: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const SingleSelectDropdown = ({
  id,
  label = "",
  description = "",
  options = [],
  selectedOption = "",
  showRefreshButton = false,
  refreshType,
  classes = "",
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleChange = (option) => {
    setValue(option ? option.value : "");
  };

  const placeholder = intl.formatMessage({ id: "FORM.SELECT" });

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label && (
        <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>
      )}
      <div
        className="flex-help"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Select
          id={id}
          aria-labelledby={ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)}
          aria-describedby={ariaDescribedBy || (description ? `${id}-description` : undefined)}
          options={options}
          value={options.find((option) => option.value === value)}
          onChange={handleChange}
          className={classes}
          placeholder={placeholder}
          {...props}
        />
        {showRefreshButton && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() => handleRefreshClick(refreshType, setIsRefreshing, intl)}
            style={{
              marginLeft: "0.75em",
              cursor: "pointer",
              color: "#575757",
              fontSize: "1.5em"
            }}
            className={isRefreshing ? "spin" : ""}
          />
        )}
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

SingleSelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  selectedOption: PropTypes.string,
  showRefreshButton: PropTypes.bool,
  refreshType: (props, propName, componentName) => {
    if (props.showRefreshButton && !props[propName]) {
      return new Error(
        `${propName} is required when showRefreshButton is true in ${componentName}`
      );
    }
    return null;
  },
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const MultiSelectDropdown = ({
  id,
  label,
  description,
  options = [],
  showRefreshButton = false,
  refreshType,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleChange = (selectedOptions) => {
    setValue(selectedOptions.map((option) => option.value));
  };

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>
      )}
      <div
        className="flex-help"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Select
          id={id}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : undefined)
          }
          options={options}
          isMulti
          value={options.filter(
            (option) => value && value.includes(option.value)
          )}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
          className={classes ? classes : ""}
          {...props}
        />
        {showRefreshButton && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() =>
              handleRefreshClick(refreshType, setIsRefreshing, intl)
            }
            style={{
              marginLeft: "0.75em",
              cursor: "pointer",
              color: "#575757",
              fontSize: "1.5em"
            }}
            className={isRefreshing ? "spin" : ""}
          />
        )}
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

MultiSelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  options: PropTypes.array.isRequired,
  showRefreshButton: PropTypes.bool,
  refreshType: requireRefreshType,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const MultiSelectDropdownWithInput = ({
  id,
  label = "",
  description = "",
  options = [],
  showRefreshButton = false,
  refreshType = null,
  classes = "",
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  const [, meta, helpers] = useField(id);
  const intl = useIntl();
  const { value } = meta;
  const { setValue } = helpers;
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleChange = (selectedOptions) => {
    setValue(selectedOptions);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setValue([...value, newOption]);
  };

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label !== "" && (
        <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>
      )}
      <div
        className="flex-help"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Creatable
          id={id}
          name={id}
          isMulti
          value={value}
          onChange={handleChange}
          onCreateOption={handleCreateOption}
          options={options}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
          noOptionsMessage={() => "Type to add new option"}
          className={classes}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : "")
          }
          {...props}
        />
        {showRefreshButton && refreshType && (
          <FontAwesomeIcon
            icon={faSync}
            onClick={() =>
              handleRefreshClick(refreshType, setIsRefreshing, intl)
            }
            style={{
              marginLeft: "0.75em",
              cursor: "pointer",
              color: "#575757",
              fontSize: "1.5em"
            }}
            className={isRefreshing ? "spin" : ""}
          />
        )}
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

MultiSelectDropdownWithInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  options: PropTypes.array,
  showRefreshButton: PropTypes.bool,
  refreshType: requireRefreshType,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const DateField = ({
  id,
  label,
  description,
  classes,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  const [, meta, helpers] = useField(id);
  const { value } = meta;
  const { setValue } = helpers;
  const intl = useIntl();

  const handleChange = (date) => {
    setValue(date);
  };

  return (
    <BootstrapForm.Group controlId={id} className="mb-3">
      {!hideLabel && label && (
        <BootstrapForm.Label id={`${id}-label`}>{label}</BootstrapForm.Label>
      )}
      <div style={{ margin: 10, marginLeft: 0 }}>
        <DatePicker
          id={id}
          selected={value && new Date(value)}
          onChange={handleChange}
          className={classes ? classes : ""}
          locale={intl.locale}
          placeholderText={intl.formatMessage({ id: "FORM.SELECT" })}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : undefined)
          }
          {...props}
        />
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  classes: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};

export const SliderField = ({
  id,
  label,
  description,
  hideLabel = false,
  hideDescription = false,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  const [, meta, helpers] = useField(id);
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <BootstrapForm.Group className="mb-3">
      {!hideLabel && label && (
        <BootstrapForm.Label htmlFor={`${id}-slider`} id={`${id}-label`}>
          {label}
        </BootstrapForm.Label>
      )}
      <div style={{ margin: 10, marginLeft: 0 }}>
        <Slider
          id={`${id}-slider`}
          style={{ marginBottom: 20 }}
          onChange={handleChange}
          min={1}
          max={99}
          value={value}
          marks={{ 1: "1%", 50: "50%", 99: "99%" }}
          handleRender={handleRender}
          aria-labelledby={
            ariaLabelledBy || (hideLabel ? undefined : `${id}-label`)
          }
          aria-describedby={
            ariaDescribedBy || (description ? `${id}-description` : undefined)
          }
          {...props}
        />
      </div>
      <ErrorMessage
        name={id}
        component={BootstrapForm.Control.Feedback}
        type="invalid"
      />
      {!hideDescription && description && (
        <BootstrapForm.Text id={`${id}-description`} muted>
          {description}
        </BootstrapForm.Text>
      )}
    </BootstrapForm.Group>
  );
};

SliderField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideDescription: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string
};
