import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../../helpers";
import { useEffect, useState } from "react";
import "../style/style.scss";
import axios from "axios";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";

const MktoQSAppenderWebhookUrl = () => {
  const intl = useIntl();
  // State variable to store the URL
  const [urlToCopy, setUrlToCopy] = useState<string>("");
  const [showContentCopied, setShowContentCopied] = useState<boolean>(false);
  const [refetchAPIKey, setRefetchAPIKey] = useState<boolean>(false);
  const [isGeneratingAPIKey, setIsGeneratingAPIKey] = useState<boolean>(false);
  const [isAPIKeyExists, setIsAPIKeyExists] = useState<boolean>(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "#fafafa",
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  // Javascript Clipboard API - It copies the URL to the Clipboard
  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setShowContentCopied(true);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setShowContentCopied(false);
    }, 1500);
  };

  useEffect(() => {
    axios
      .get("/qs_appender/webhook")
      .then((response: any) => {
        setUrlToCopy(response.data);
        setIsAPIKeyExists(true);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [refetchAPIKey]);

  const handleRegenerateApiKey = () => {
    setIsGeneratingAPIKey(true);
    axios
      .post("/qs_appender/api_key")
      .then((response: any) => {
        setRefetchAPIKey(!refetchAPIKey);
        setIsGeneratingAPIKey(false);
        Toast.fire({
          icon: "success",
          title: intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.ALERT.TEXT"
          })
        });
      })
      .catch((error: any) => {
        console.error("Error : ", error);
        setIsGeneratingAPIKey(false);
      });
  };

  return (
    <div className="row m-3">
      <div className="col-12">
        <p className="text-muted">
          {intl.formatMessage({
            id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.DESC"
          })}
          <a href="#"> Get step-by-step instructions</a>.
        </p>
        {isAPIKeyExists && (        
        <div className="py-3 px-3 w-100 d-flex align-items-center justify-content-between rounded gap-5">
          <code className="fs-5 m-2 p-2 text-truncate" data-tooltip-id="fullURL" onClick={handleCopyUrl} role="button">
            {urlToCopy}
          </code>
          <Tooltip id="fullURL" key="fullURL" content={urlToCopy} place="top" variant="dark" style={{ zIndex: 100 }} />

          {!showContentCopied ? (
            <button
              data-tooltip-id="copyToClipboard"
              type="button"
              className="copyBtn me-2"
              onClick={handleCopyUrl}
              aria-label={intl.formatMessage({
                id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.URL.COPY"
              })}
            >
              <KTIcon iconName="copy" iconType="outline" className="iconBtn fs-1" />
              <Tooltip
                id="copyToClipboard"
                key="copyToClipboard"
                content={intl.formatMessage({
                  id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.URL.COPY"
                })}
                place="top"
                variant="dark"
                style={{ zIndex: 100 }}
              />
            </button>
          ) : (
            <div className="badge bg-light-success text-success p-1 pr-3">
              <span className="svg-icon svg-icon-muted svg-icon-2hx text-success">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              {intl.formatMessage({
                id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.URL.COPIED"
              })}
            </div>
          )}
        </div>
        )}
        <div className="d-flex justify-content-end gap-10">
          <Button
            type="button"
            variant="primary"
            className="mt-10"
            onClick={handleRegenerateApiKey}
            disabled={isGeneratingAPIKey}
          >
            {!isGeneratingAPIKey ? 
              (
                isAPIKeyExists ? "Regenerate API Key" : "Create API Key"
             ) : (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.APIKEY.BUTTON.GENERATING"
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MktoQSAppenderWebhookUrl;
