import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useAuth } from "../../../pages/auth";
import { hasManageLimitsPermission } from "../../../helpers/permissions";

import Form from "../../../layout/components/form/Form";
import PageHeader from "../../../layout/components/content/PageHeader";
import {
  TextField,
  SingleSelectDropdown,
  DateField,
  MultiSelectDropdown,
  SliderField
} from "../../../layout/components/form/FormFields";

import InsSpinner from "../../../layout/components/spinner/InsSpinner";

import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";

interface SfdcConfigs {
  enabled: boolean;
}

export default function MktoSettings() {
  const { currentUser } = useAuth();
  const hasLimitsPermission = currentUser
    ? hasManageLimitsPermission(currentUser)
    : false;
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [smartLists, setSmartLists] = useState<any>(undefined);
  const [programs, setPrograms] = useState<any>(undefined);
  const [oppFields, setOppFields] = useState<any>(undefined);
  const [mktoDataFetchStatus, setMktoDataFetchStatus] =
    useState<any>(undefined);
  const [sfdcConfigs, setSfdcConfigs] = useState<SfdcConfigs>({
    enabled: true
  });

  const intl = useIntl();

  const fields = [
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.SMARTLIST" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.SMARTLIST.DESC"
      }),
      id: "mkto_smart_list_id",
      options: smartLists,
      fieldComponent: (props: any) => (
        <SingleSelectDropdown
          {...props}
          disabled={!sfdcConfigs.enabled}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      ),
      showRefreshButton: true,
      refreshType: "smartlist"
    },
    {
      label: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.ALLRECORDSSMARTLIST"
      }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.ALLRECORDSSMARTLIST.DESC"
      }),
      id: "mkto_all_records_smart_list_id",
      options: smartLists,
      fieldComponent: (props: any) => (
        <SingleSelectDropdown
          {...props}
          disabled={!sfdcConfigs.enabled}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      ),
      showRefreshButton: true,
      refreshType: "smartlist"
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.EXCLUDEPROGMEM" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.EXCLUDEPROGMEM.DESC"
      }),
      id: "mkto_program_ids_ignore_members",
      options: programs,
      fieldComponent: (props: any) => (
        <MultiSelectDropdown
          {...props}
          disabled={!sfdcConfigs.enabled}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      ),
      showRefreshButton: true,
      refreshType: "programs"
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.OPPAMOUNT" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.OPPAMOUNT.DESC"
      }),
      id: "mkto_opportunity_amount_field",
      options: oppFields,
      fieldComponent: (props: any) => (
        <SingleSelectDropdown
          {...props}
          disabled={!sfdcConfigs.enabled}
          placeholder={intl.formatMessage({ id: "FORM.SELECT" })}
        />
      )
    },

    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.EXTRACTSTART" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.EXTRACTSTART.DESC"
      }),
      id: "mkto_start_date",
      validationSchema: Yup.date(),
      fieldComponent: DateField
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXDAILYCALLS" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.MAXDAILYCALLS.DESC"
      }),
      id: "mkto_max_daily_calls",
      validationSchema: Yup.number(),
      fieldComponent: TextField
    },
    {
      label: intl.formatMessage({ id: "SETTINGS.MKTO.GENERAL.MAXLEADRECORDS" }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.MAXLEADRECORDS.DESC"
      }),
      id: "mkto_max_lead_records",
      validationSchema: Yup.number(),
      fieldComponent: TextField
    },
    {
      label: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.MAXLEADNOTIFICATION"
      }),
      description: intl.formatMessage({
        id: "SETTINGS.MKTO.GENERAL.MAXLEADNOTIFICATION.DESC"
      }),
      id: "mkto_max_leads_notification_threshold",
      fieldComponent: SliderField
    }
  ];

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    setSubmitting(true);

    try {
      await axios.post("/configs/insentric-mkto", {
        opportunity_amount_field: values.mkto_opportunity_amount_field,
        smart_list_id: values.mkto_smart_list_id,
        all_records_smart_list_id: values.mkto_all_records_smart_list_id,
        start_date: values.mkto_start_date,
        max_daily_calls: values.mkto_max_daily_calls,
        max_lead_records: values.mkto_max_lead_records,
        program_ids_ignore_members: values.mkto_program_ids_ignore_members,
        max_leads_notification_threshold:
          values.mkto_max_leads_notification_threshold
      });

      toast.success(
        intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }),
        toastSettings("success")
      );
    } catch (err: any) {
      toast.error(
        `${intl.formatMessage({
          id: "SETTINGS.TOAST.ERROR"
        })}: ${err.message}`,
        toastSettings("error")
      );
    }
  };

  useEffect(() => {
    setMktoDataFetchStatus(undefined);

    Promise.all([
      axios.get("/status/data"),
      axios.get("/marketo/settings/marketo_smart_lists"),
      axios.get("/marketo/settings/marketo_programs"),
      axios.get("/marketo/settings/marketo_fields/opportunity", {
        params: {
          data_types: ["currency"]
        }
      }),
      axios.get("/configs/insentric-mkto")
    ])
      .then((response: any) => {
        const [
          status,
          smartListsResponse,
          programsResponse,
          oppFieldsResponse,
          initialValues
        ] = response;

        if (status?.data?.mkto?.lead_fields.last_successful_fetch !== "") {
          setMktoDataFetchStatus(true);
        } else {
          setMktoDataFetchStatus(false);
        }

        setSmartLists(
          smartListsResponse.data.map((smartList: any) => {
            const parentProgramName =
              smartList._dl_parent_program && smartList._dl_parent_program.name
                ? smartList._dl_parent_program.name
                : "";

            return {
              ...smartList,
              label:
                parentProgramName.length > 0
                  ? `${parentProgramName}.${smartList.name}`
                  : smartList.name,
              value: smartList.id
            };
          })
        );

        setPrograms(
          programsResponse.data.map((program: any) => ({
            ...program,
            label: program.name,
            value: program.id
          }))
        );

        setOppFields(
          oppFieldsResponse.data.map((oppField: any) => ({
            ...oppField,
            label: oppField.displayName,
            value: oppField.name
          }))
        );

        setInitialValues({
          mkto_opportunity_amount_field:
            initialValues.data.opportunity_amount_field,
          mkto_smart_list_id: initialValues.data.smart_list_id || undefined,
          mkto_all_records_smart_list_id:
            initialValues.data.all_records_smart_list_id || undefined,
          mkto_start_date: initialValues.data.start_date,
          mkto_max_daily_calls: initialValues.data.max_daily_calls,
          mkto_max_lead_records: initialValues.data.max_lead_records,
          mkto_program_ids_ignore_members:
            initialValues.data.program_ids_ignore_members || [],
          mkto_max_leads_notification_threshold:
            initialValues.data.max_leads_notification_threshold || 90
        });
      })
      .catch((error) => {
        console.error("Error fetching initial values:", error);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    mkto_smart_list_id: Yup.string().required(),
    mkto_all_records_smart_list_id: Yup.string().required(),
    mkto_program_ids_ignore_members: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required()
        })
      )
      .required(),
    mkto_opportunity_amount_field: Yup.string().required(),
    mkto_start_date: Yup.date().nullable(),
    mkto_max_daily_calls: Yup.number().required(),
    mkto_max_lead_records: Yup.number().required(),
    mkto_max_leads_notification_threshold: Yup.number().required()
  });

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MARKETO"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.MKTOGENERALSETTINGS"
          instructions=""
        />
      </div>
      <div className="card px-3 mb-xl-3">
        {mktoDataFetchStatus === undefined ? (
          <div>
            <InsSpinner />
          </div>
        ) : mktoDataFetchStatus ? (
          initialValues ? (
            <div className="mt-10 mb-10">
              <Container fluid className="form-container">
                <div>
                  <Form
                    fields={fields}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </Container>
            </div>
          ) : (
            <div>
              <InsSpinner />
            </div>
          )
        ) : (
          <div className="mt-10 mb-10">
            <Container fluid>
              <div className="d-flex justify-content-center">
                <h3>
                  {intl.formatMessage({
                    id: "SETTINGS.MKTO.GENERAL.DATAFETCHING.WAITMESSAGE"
                  })}
                </h3>
              </div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
