import React from "react";
import { replaceFlagChars } from "../utils/utils";

const addDefaultImg = (e: any) => {
  e.target.src = "/media/flags/unknown.png";
};

const Group = React.memo(({ children, label }: { children: any; label: any}) => {
  const flag = replaceFlagChars(label);
  return (
    <div className="tz-group ml-5">
      <div className="d-flex flex-row align-items-center">
        {flag && flag !== "local" && (
          <div className="tz-flag">
            <img onError={addDefaultImg} src={`/media/flags/${flag}.svg`} alt={`${flag} flag`} className="h-20px" />
          </div>
        )}
        <div className="tz-groupLabel">{label}</div>
      </div>
      <div className="tz-groupOptions">{children}</div>
    </div>
  );
});

Group.displayName = "Group";

export default Group;
