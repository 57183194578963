import { Field } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import { LockAlert } from "./Alerts";
import { GenerateNewPassworIcon } from "./GenerateNewPasswordIcon";
import { CopyToClipboardIcon } from "./CopyToClipboardIcon";
import { ShowPasswordIcon } from "./ShowPasswordIcon";
import { useState } from "react";
import { checkPassword, passwordGenerator, useKeyLock } from "../../core/_helper";

const PasswordFields = ({
  setPasswordRequirements,
  setFieldValue,
  values,
  errors,
  touched
}: {
  setPasswordRequirements: any;
  setFieldValue: any;
  values: any;
  errors: any;
  touched: any;
}) => {
  const [generatedPassword, setGeneratedPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [copied, setCopied] = useState(false);

  const capsLockOn = useKeyLock("CapsLock");
  const numLockOn = useKeyLock("NumLock");
  const scrollLockOn = useKeyLock("ScrollLock");

  // Javascript Clipboard API - It copies the generated password to the Clipboard
  const handleCopyPassword = async () => {
    setCopied(true);
    try {
      await navigator.clipboard.writeText(generatedPassword);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  // Generates a new password every time the refresh icon is clicked at the end of the password line
  const handleGenerateNewPassword = () => {
    const password = passwordGenerator();
    setGeneratedPassword(password);
    setPasswordRequirements(checkPassword(password, confirmPassword));
    setFieldValue("password", password);
  };

  return (
    <>
      <label htmlFor="password" className="mb-2">
        <span className="fs-4 fw-bold">Password</span>
      </label>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "100%"
        }}
      >
        <Field
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          as={BootstrapForm.Control}
          isInvalid={false}
          className="form-input"
          style={{ paddingRight: "30px" }}
          value={generatedPassword}
          onChange={(e: any) => {
            setFieldValue("password", e.target.value);
            setGeneratedPassword(e.target.value);
            setPasswordRequirements(
              checkPassword(e.target.value, confirmPassword)
            );
          }}
          placeholder="Password"
        />
        <div>
          <ShowPasswordIcon
            id="password"
            showPassword={showPassword}
            setShowPassword={() => setShowPassword(!showPassword)}
          />
          <CopyToClipboardIcon
            handleCopyPassword={handleCopyPassword}
            copied={copied}
          />
          <GenerateNewPassworIcon
            handleGenerateNewPassword={handleGenerateNewPassword}
          />
        </div>
      </div>
      {errors.password && touched.password && (
        <div className="text-danger">{errors.password}</div>
      )}

      <label htmlFor="password" className="mb-2 mt-10">
        <span className="fs-4 fw-bold">Confirm password</span>
      </label>

      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "100%"
        }}
      >
        <Field
          type={showConfirmPassword ? "text" : "password"}
          id="confirmPassword"
          name="confirmPassword"
          as={BootstrapForm.Control}
          isInvalid={false}
          className="form-input"
          style={{ paddingRight: "30px" }}
          placeholder="Confirm password"
          value={confirmPassword}
          onChange={(e: any) => {
            setFieldValue("confirmPassword", e.target.value);
            setConfirmPassword(e.target.value);
            setPasswordRequirements(
              checkPassword(generatedPassword, e.target.value)
            );
          }}
        />
        <div>
          <ShowPasswordIcon
            id="confirmPassword"
            showPassword={showConfirmPassword}
            setShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </div>
      </div>
      {errors.confirmPassword && touched.confirmPassword && (
        <div className="text-danger">{errors.confirmPassword}</div>
      )}

      <div className="d-flex justify-content-end mt-5">
        <button type="submit" className="btn btn-primary">
          Set Password
        </button>
      </div>
      
      {capsLockOn && <LockAlert alert="capsLock" />}
      {/* {!numLockOn && <LockAlert alert="numLock" />} */}
      {scrollLockOn && <LockAlert alert="scrollLock" />}
    </>
  );
};

export default PasswordFields;
