import axios, { Axios, AxiosError } from "axios";
import { useState, useEffect, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../localization/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../layout/core";
import { MasterInit } from "../layout/MasterInit";
import { AuthInit } from "./auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FrigadeProvider } from "@frigade/react";
import { apiGet } from "../helpers/apiHelpers";

import packageInfo from "../../package.json";

function setupAxios(axios: Axios) {
  axios.interceptors.request.use(
    (config) => {
      config.headers["Api-Version"] = packageInfo.backendVersion;

      if (!config.url?.startsWith("/auth") && !config.url?.startsWith("/assets")) {
        config.baseURL = "/api";
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // Any status codes that fall outside the range of 2xx will cause this function to trigger
      if (error.response && error.response.status === 500) {
        console.error(error.response.data);
        return error.response;
      }
      return Promise.reject(error);
    }
  );
}

async function fetchUserInfo() {
  try {
    const response = await apiGet("/auth/me");
    if (!response) {
      throw new Error("No response from API");
    }
    return {
      instanceId: response.roles[0]._firestore.projectId || null, // BUG: NOT EXIST IN RESPONSE
      userId: response.email || null // TODO: Replace with Firebase ID
    };
  } catch (error) {
    console.error("Error fetching user info:", error);
    return {
      instanceId: null,
      userId: null
    };
  }
}

const App = () => {
  const [userInfo, setUserInfo] = useState({
    userId: undefined,
    instanceId: undefined
  });
  useEffect(() => {
    setupAxios(axios);
    fetchUserInfo().then((info) => {
      setUserInfo(info);
    });
  }, []);

  // Check if userId and instanceId are available before rendering FrigadeProvider
  const isUserInfoAvailable =
    userInfo.userId !== undefined && userInfo.instanceId !== undefined;

  return isUserInfoAvailable ? (
    <FrigadeProvider
      publicApiKey="api_public_oBoJRyzhBkBFDOuBVHwb2PGnXQQ2mPVt8rINdVl7uWCsIHrHDLELZoYqAucjfzdr"
      userId={userInfo.userId}
      organizationId={userInfo.instanceId}
    >
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <ToastContainer limit={5} />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </FrigadeProvider>
  ) : (
    <LayoutSplashScreen /> // Or any other placeholder/loading component
  );
};

export { App };
