import { useIntl } from "react-intl";

const GenSettingsSaveButton = ({ isSaving } : { isSaving: boolean}) => {
  const intl = useIntl();

  return (
    <div className="d-flex justify-content-end">
      <button
        id="btn_save_account_settings"
        className="btn btn-primary "
        type="submit"
        disabled={isSaving}
      >
        {!isSaving ? (
          intl.formatMessage({
            id: "BUTTON.SAVE"
          })
        ) : (
          <span className="indicator-progress" style={{ display: "block" }}>
            {intl.formatMessage({
              id: "BUTTON.SAVING"
            })}{" "}
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </div>
  );
};

export default GenSettingsSaveButton;
