import { useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../firebase";
import { useIntl } from "react-intl";
import clsx from "clsx";

export function ForgotPassword() {
  const intl = useIntl();
  const [isDelay, setIsDelay] = useState(false);

  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegExp, { message: "AUTH.FORGOT.EMAILFORMAT" })
      .required("AUTH.FORGOT.EMAILREQUIRED")
  });

  const initialValues = { email: "" };

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      setIsDelay(true);
      setTimeout(() => {
        setIsDelay(false);
      }, 2000);
      try {
        await sendPasswordResetEmail(auth, values.email);
        setSuccessMessage(intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" }));
        actions.resetForm();
      } catch (error: any) {
        setSuccessMessage(intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" }));
      }
      setLoading(false);
    }
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "AUTH.FORGOT.TITLE" })}
        </h1>
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.FORGOT.DESC" })}
        </div>
      </div>

      {successMessage && (
        <div className="alert alert-success bg-light-success border border-success border-dashed d-flex align-items-center p-5">
          <i className="ki-duotone ki-shield-tick fs-2hx text-success me-4">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="d-flex flex-column">
            <span>{successMessage}</span>
          </div>
        </div>
      )}

      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-gray-900 fs-6">
          {intl.formatMessage({ id: "AUTH.FORGOT.EMAIL" })}
        </label>
        <input
          type="email"
          placeholder={intl.formatMessage({ id: "AUTH.FORGOT.EMAIL" })}
          autoComplete="off"
          {...formik.getFieldProps("email")}
          name="email"
          id="name"
          className={clsx("form-control bg-transparent", {
            "is-invalid": formik.touched.email && formik.errors.email
          })}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">
                {intl.formatMessage({ id: formik.errors.email })}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={isDelay || !formik.isValid || formik.isSubmitting}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "AUTH.FORGOT.SUBMIT" })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({ id: "AUTH.GENERAL.PLEASEWAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "AUTH.GENERAL.CANCEL" })}
          </button>
        </Link>{" "}
      </div>
    </form>
  );
}
