import { useState } from "react";
import { Form, Formik } from "formik";
import Requirements from "./Requirements";
import { useIntl } from "react-intl";
import { passwordValidationSchema } from "../schema/passwordValidationSchema";
import PasswordFields from "./PasswordFields";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../../../../../firebase";
import { useVerification } from "../../core/VerificationProvider";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../../layout/components/toast/Toast";

const PasswordGen = () => {
  const intl = useIntl();
  const verification = useVerification();
  const [isPasswordSet, setIsPasswordSet] = useState(false);

  const [passwordRequirements, setPasswordRequirements] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isNotEmpty: false,
    isNotForbidden: true,
    isLongEnough: false,
    isMatching: false
  });

  const onSubmit = (values: any) => {
    console.log(values);
    confirmPasswordReset(auth, verification.actionCode, values.password)
      .then((response) => {
        toast.success(
          "The Password has been successfully set",
          toastSettings("success")
        );
      })
      .catch((error) => {
        console.log("Error : ", error);
        toast.error(`Error : ${error}`, toastSettings("error"));
      })
      .finally(() => {
        setIsPasswordSet(true);
      });
  };

  return (
    <>
      {isPasswordSet ? (
        <>
          <h2>{intl.formatMessage({ id: "AUTH.SETPASSWORD.SUCCESS" })}</h2>
          <p>{intl.formatMessage({ id: "AUTH.SETPASSWORD.SUCCESS.DESCRIPTION" })}</p>
          <Link to="/auth/login" className="btn btn-primary">
            {intl.formatMessage({ id: "AUTH.BACKTOLOGIN" })}
          </Link>
        </>
      ) : (
        <>
          <div className="pb-10">
            <h2>{intl.formatMessage({ id: "AUTH.RESETPASSWORD.TITLE" })}</h2>
            <p>
              {intl.formatMessage({ id: "AUTH.RESETPASSWORD.DESCRIPTION" })}
            </p>
          </div>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={passwordValidationSchema}
            onSubmit={onSubmit}
          >
            {({ values, touched, errors, setFieldValue }) => {
              return (
                <Form id="set-password">
                  <PasswordFields
                    setPasswordRequirements={setPasswordRequirements}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Form>
              );
            }}
          </Formik>
          <Requirements requirements={passwordRequirements} />
        </>
      )}
    </>
  );
};

export default PasswordGen;
