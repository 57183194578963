import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { profileValidationSchema } from "../schema/profileValidationSchema";
import { TextField } from "../../../../layout/components/form/FormFields";
import { useAuth } from "../../../auth";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import { TimezoneSelect } from "../../../../layout/components/timezone";

const ProfileSettingsForm = () => {
  const { currentUser } = useAuth();
  const intl = useIntl();
  const [userInfo, setUserInfo] = useState<any>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");

  useEffect(() => {
    if (currentUser) {
      axios
        .get(`/users/${currentUser.id}`)
        .then((response: any) => {
          setUserInfo(response.data);
          setSelectedTimezone(currentUser.timezone || "");
        })
        .catch((error: any) => {
          console.error("Error fetching user data :", error);
        });
    }
  }, [currentUser]);

  const onSubmit = (values: any) => {
    if (currentUser) {
      const payload = {
        user_properties: {
          first_name: values.first_name,
          last_name: values.last_name,
          timezone: selectedTimezone,
          // I recognize this is less than ideal and needs fixing. 
          // CG 2024-08-27: Include required fields from currentUser.
          active: currentUser?.active,
          email: currentUser.email,
          language: currentUser?.language,
          login_method: currentUser?.login_method,
        },
      };

      axios
        .put(`/users/${currentUser.id}`, payload)
        .then((response: any) => {
          toast.success("The User has been successfully updated", toastSettings("success"));
        })
        .catch((error: any) => {
          console.error("Error updating user:", error.response?.data);
          toast.error("The User has not been updated", toastSettings("error"));
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: currentUser?.first_name,
          last_name: currentUser?.last_name,
          timezone: currentUser?.timezone || "",
        }}
        validationSchema={profileValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, touched, errors, setFieldValue }) => {
          return (
            <Form id="profile-page">
              <TextField
                id="first_name"
                name="first_name"
                label={intl.formatMessage({
                  id: "USER.FIRSTNAME",
                })}
                placeholder=""
                touched={touched}
                errors={errors}
                description=""
                classes=""
                ariaLabelledBy=""
                ariaDescribedBy=""
              />
              <TextField
                id="last_name"
                name="last_name"
                label={intl.formatMessage({
                  id: "USER.LASTNAME",
                })}
                placeholder=""
                touched={touched}
                errors={errors}
                description=""
                classes=""
                ariaLabelledBy=""
                ariaDescribedBy=""
              />
              <div className="mb-3">
                <label className="form-label" id="timezone-label">
                  {intl.formatMessage({
                    id: "USER.TIMEZONE",
                  })}
                </label>
                <TimezoneSelect 
                  ariaLabelledBy="timezone-label"
                  value={selectedTimezone} 
                  onChange={setSelectedTimezone} />
                {!!errors.timezone && touched.timezone && (
                  <p className="text-danger">
                    {intl.formatMessage({
                      id: errors.timezone,
                    })}
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-end mt-5">
                <button type="submit" className="btn btn-primary">
                  {intl.formatMessage({
                    id: "FORM.SUBMIT",
                  })}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProfileSettingsForm;
