import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";

const GenerateNewPassworIcon = ({
  handleGenerateNewPassword
}: {
  handleGenerateNewPassword: any;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        right: "90px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
      data-tooltip-id="genNewPass"
      onClick={handleGenerateNewPassword}
    >
      <KTIcon
        iconName="arrows-circle"
        iconType="outline"
        className="iconBtn fs-1"
      />
      <Tooltip
        id="genNewPass"
        key="genNewPass"
        content="Generate New Password"
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};
export { GenerateNewPassworIcon };
