import { useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { toastSettings } from "../../../layout/components/toast/Toast";
import axios from "axios";

const ObjectSetupSaveBtn = ({platformEndpoint, ...props} : {
  platformEndpoint : any;
  object: string;
  updatedObject: any;
  hasChange: boolean;
}) => {
  const intl = useIntl();

  const [savingSpinner, setSavingSpinner] = useState<boolean>(false);

  const saveConfig = async () => {
    setSavingSpinner(true);

    // Create the payload object based on the incoming props
    const payload = {
      [props.object]: props.updatedObject
    };
    try {
      // Update Firebase with the new object
      await axios.post(`/schema/${platformEndpoint}`, payload);
      setSavingSpinner((prevState) => !prevState);
      toast.success(
        intl.formatMessage({ id: "SETTINGS.TOAST.SUCCESS" }),
        toastSettings("success")
      );
    } catch (error) {
      console.log(error);
      setSavingSpinner((prevState) => !prevState);
    }
  };

  return (
    <button
      type="button"
      onClick={saveConfig}
      className={clsx("btn btn-primary me-2", {
        disabled: !props.hasChange
      })}
    >
      {!savingSpinner && (
        <span className="indicator-label">
          {intl.formatMessage({ id: "BUTTON.SAVE" })}
        </span>
      )}
      {savingSpinner && (
        <span className="indicator-progress" style={{ display: "block" }}>
          {intl.formatMessage({ id: "FORM.PLEASEWAIT" })}{" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </button>
  );
};

export default ObjectSetupSaveBtn;
