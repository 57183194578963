import { KTIcon } from "../../../../helpers";

const ERROR_MESSAGES = {
  capsLock:
    "<strong>Caps Lock</strong> is on!</br>Having <strong>Caps Lock</strong> on may cause you to enter your password incorrectly.",
  numLock:
    "<strong>Num Lock</strong> is off!</br>Having <strong>Num Lock</strong> off may cause you to enter your password incorrectly.",
  scrollLock:
    "<strong>Scroll Lock</strong> is on!</br>Having <strong>Scroll Lock</strong> on may cause you to enter your password incorrectly."
};

const LockAlert = ({ alert }: { alert: keyof typeof ERROR_MESSAGES }) => {
  return (
    <div id="capslock-warning" className="d-flex align-items-center bg-light-warning rounded border border-warning my-3 p-2 gap-2">
      <KTIcon
        iconName="information-3"
        iconType="outline"
        className="iconBtn fs-1 text-warning"
      />
      <span className="text-warning" dangerouslySetInnerHTML={{__html: ERROR_MESSAGES[alert]}}></span>
    </div>
  );
};

export { LockAlert };
