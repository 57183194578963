import PropTypes from "prop-types";
import cx from "classnames";

const Dropdown = ({ children, isOpen, target, onClose }: { children: any; isOpen: any; target: any; onClose: any }) => (
  <div className={cx("tz-dropdown", { "tz-isOpen": isOpen })}>
    {target}
    <div className="tz-menu">{children}</div>
    <div className="tz-blanket" onClick={onClose} />
  </div>
);

Dropdown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  target: PropTypes.node,
  onClose: PropTypes.func
};

export default Dropdown;
