import React from 'react';
import PropTypes from 'prop-types';
import {
  MultiSelectDropdownWithInput,
  NumberField,
  TextField
} from "../../../../../layout/components/form/FormFields";
import { useIntl } from "react-intl";
import { useInsJSFilesRequest } from "../../core/InsJSFilesProvider";

interface Step1Props {
  errors: Record<string, any>;
  touched: Record<string, any>;
  values: Record<string, any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setIsInitialAndConversion: (value: boolean) => void;
}

const Step1: React.FC<Step1Props> = ({ errors, touched, values, setFieldValue, setIsInitialAndConversion }) => {
  const intl = useIntl();
  const { itemIdForUpdate } = useInsJSFilesRequest();
  const isItemForUpdate = itemIdForUpdate !== undefined;

  return (
    <div className="w-100">
      <h1>{intl.formatMessage({
              id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1"
            })}</h1>
      <div className="text-muted mt-3">
        {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.INSTRUCTIONS" })}
      </div>
      <hr />
      <div className="w-100 d-flex flex-column">
        <div className="m-3">
          <h2 id="main-domain-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAIN" })}
          </h2>
          <div id="main-domain-description" className="text-muted my-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAIN.INSTRUCTIONS" })}
          </div>
          <TextField
            id="maindomain"
            label=""
            description=""
            placeholder="example.com"
            disabled={!isItemForUpdate}
            optional={false}
            classes={errors.maindomain && touched.maindomain ? "border-danger" : ""}
            isInvalid={false}
            ariaLabelledBy="main-domain-label"
            ariaDescribedBy="main-domain-description"
          />
          {errors.maindomain && touched.maindomain && (
            <p className="text-danger">
              {intl.formatMessage({ id: errors.maindomain })}
            </p>
          )}
        </div>
        <div className="m-3">
                  <h2 className="cookie-months-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.COOKIES" })}
          </h2>
          <NumberField
            id="cookie_months"
            label=""
            description=""
            optional={false}
            classes={errors.cookie_months && touched.cookie_months ? "border-danger" : ""}
            min={1}
            max={12}
            placeholder={6}
            ariaLabelledBy="cookie-months-label"
            ariaDescribedBy="cookie-months-description"
            hideLabel={false}
            hideDescription={false}
          />
          {errors.cookie_months && touched.cookie_months && (
            <p className="text-danger">
              {intl.formatMessage({ id: errors.cookie_months })}
            </p>
          )}
          <div id="cookie-months-description" className="text-muted mt-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.COOKIES.INSTRUCTIONS" })}
          </div>
        </div>
        <div className="m-3">
          <div className="mb-10">
            <div className="form-check form-switch form-check-custom form-check-solid justify-content-between align-items-center">
              <h2>
                {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.INITIALCONVERSION" })}
              </h2>
              <input
                className="form-check-input"
                type="checkbox"
                name="initialConversion"
                id="initialConversion"
                checked={values.initialConversion}
                onChange={(e: any) => {
                  setIsInitialAndConversion(e.target.checked);
                  setFieldValue("initialConversion", e.target.checked)
                }}
                aria-describedby="initial-conversion-description"
              />
            </div>
            <div id="initial-conversion-description" className="text-muted my-3">
              {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.INITIALCONVERSION.INSTRUCTIONS" })}
            </div>
          </div>
        </div>
        <div className="m-3">
          <h2 className="valid-domains-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAINLOCK" })}
          </h2>
          <div id="valid-domains-description" className="text-muted my-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.DOMAINLOCK.INSTRUCTIONS" })}
          </div>
          <MultiSelectDropdownWithInput
            id="validdomains"
            label=""
            description=""
            options={values.validdomains}
            classes={errors.validdomains && touched.validdomains ? "border-danger" : ""}
            showRefreshButton={false}
            ariaLabelledBy="valid-domains-label"
            ariaDescribedBy="valid-domains-description"
          />
          {errors.validdomains && touched.validdomains && (
            <p className="text-danger">
              {intl.formatMessage({ id: errors.validdomains })}
            </p>
          )}
        </div>
        <div className="m-3">
          <h2 id="reserved-strings-label">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.RESERVEDSTRINGS" })}
          </h2>
          <div id="reserved-strings-description" className="text-muted my-3">
            {intl.formatMessage({ id: "SETTINGS.INSENTRIC-JS.WIZARD.STEP1.RESERVEDSTRINGS.INSTRUCTIONS" })}
          </div>
          <MultiSelectDropdownWithInput
            id="reservedstrings"
            label=""
            description=""
            options={values.reservedstrings}
            showRefreshButton={false}
            ariaLabelledBy="reserved-strings-label"
            ariaDescribedBy="reserved-strings-description"
          />
        </div>
      </div>
    </div>
  );
};

Step1.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export { Step1 };
