import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";

const CheckIcon = () => {
  return <KTIcon iconName="check-circle" iconType="outline" className="iconBtn fs-1 text-success" />;
};

const NotIcon = () => {
  return <KTIcon iconName="cross-circle" iconType="outline" className="iconBtn fs-1 text-danger" />;
};

const PasswordRequirements = ({ requirements }: { requirements: any }) => {
  const intl = useIntl();
  return (
    <div className="pt-5">
      <h2>
        {intl.formatMessage({
          id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSTITLE2"
        })}
      </h2>
      <div className="card-text d-flex flex-column flex-sm-row gap-0 gap-sm-20">
        <ul className="list-group">
          <li key="item1" className="d-flex align-items-center">
            {requirements.isNotEmpty ? <CheckIcon /> : <NotIcon />}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM1"
            })}
          </li>
          <li key="item2" className="d-flex align-items-center">
            {requirements.isLongEnough ? <CheckIcon /> : <NotIcon />}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM2"
            })}
          </li>
          <li key="item3" className="d-flex align-items-center">
            {requirements.hasSpecialChar ? <CheckIcon /> : <NotIcon />}

            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM3"
            })}
          </li>
          <li key="item4" className="d-flex align-items-center">
            {requirements.hasUpperCase ? <CheckIcon /> : <NotIcon />}{" "}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM4"
            })}
          </li>
        </ul>
        <ul className="list-group">
          <li key="item5" className="d-flex align-items-center">
            {requirements.hasLowerCase ? <CheckIcon /> : <NotIcon />}{" "}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM5"
            })}
          </li>
          <li key="item6" className="d-flex align-items-center">
            {requirements.hasNumber ? <CheckIcon /> : <NotIcon />}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM6"
            })}
          </li>
          <li key="item7" className="d-flex align-items-center">
            {requirements.isNotForbidden ? <CheckIcon /> : <NotIcon />}{" "}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM7"
            })}
          </li>
          <li key="item8" className="d-flex align-items-center">
            {requirements.isMatching ? <CheckIcon /> : <NotIcon />}{" "}
            {intl.formatMessage({
              id: "SETTINGS.USER.ADDUSER.SUGGESTEDPASSLISTITEM8"
            })}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PasswordRequirements;
