import { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import { validationSchema } from "./schema/validationSchema";
import InsSpinner from "../../../../layout/components/spinner/InsSpinner";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import PageHeader from "../../../../layout/components/content/PageHeader";
import { Formik } from "formik";
import FormWrapper from "./components/FormWrapper";
import MktoQSAppenderWebhookUrl from "./components/MktoSQAppenderWebhookUrl";
import MktoSQAppenderForceUpdate from "./components/MktoSQAppenderForceUpdate";

const MktoQSAppenderSettings = () => {
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [mktoDataFetchStatus, setMktoDataFetchStatus] = useState<any>(undefined);

  const intl = useIntl();

  const handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: any }) => {
    setSubmitting(true);

    try {
      await axios.post("/configs/insentric-mkto-qs-append", {
        parameters_token: values.mkto_qs_appender_parameters_token,
        update_non_templated: values.mkto_qs_appender_update_non_templated,
        url_parameters: values.mkto_qs_appender_url_parameters.map((value: any) => value.value),
        excluded_domains: values.mkto_qs_appender_excluded_domains.map((value: any) => value.value),
        start_date: values.mkto_qs_appender_start_date !== "" ? values.mkto_qs_appender_start_date : undefined,
        activate_schedule: values.mkto_qs_appender_run_in_two_hours
      });

      toast.success(intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.TOAST.SUCCESS" }), toastSettings("success"));
    } catch (err: any) {
      toast.error(
        `${intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.TOAST.ERROR" })}: ${err.message}`,
        toastSettings("error")
      );
    }
  };

  useEffect(() => {
    setMktoDataFetchStatus(undefined);

    Promise.all([axios.get("/status/data"), axios.get("/configs/insentric-mkto-qs-append")])
      .then((response: any) => {
        const [status, sqAppenderResponse] = response;

        if (status?.data?.mkto?.lead_fields.last_successful_fetch !== "") {
          setMktoDataFetchStatus(true);
        } else {
          setMktoDataFetchStatus(false);
        }

        setInitialValues({
          mkto_qs_appender_parameters_token: sqAppenderResponse.data.parameters_token,
          mkto_qs_appender_url_parameters: sqAppenderResponse.data.url_parameters.map((value: any) => ({
            label: value,
            value: value
          })),
          mkto_qs_appender_excluded_domains: sqAppenderResponse.data.excluded_domains.map((value: any) => ({
            label: value,
            value: value
          })),
          mkto_qs_appender_start_date: sqAppenderResponse.data.start_date || undefined,
          mkto_qs_appender_run_in_two_hours: sqAppenderResponse.data.activate_schedule || false,
          mkto_qs_appender_update_non_templated: sqAppenderResponse.data.update_non_templated
        });
      })
      .catch((error) => {
        console.error("Error fetching initial values:", error);
      });
  }, []);

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.MARKETO"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.QSAPPEND"
          instructions="SETTINGS.MKTO.QSAPPEND.INSTRUCTIONS"
        />
      </div>
      {mktoDataFetchStatus === undefined ? (
        <div>
          <InsSpinner />
        </div>
      ) : mktoDataFetchStatus ? (
        initialValues ? (
          <div className="app-content flex-column-fluid">
            <div className="app-container container-fluid">
              <div className="row g-5 g-xxl-8">
                <div className="col-xl-6">
                  <div className="card mb-5 mb-xxl-8">
                    <div className="card-header">
                      <h2 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3">
                          {intl.formatMessage({
                            id: "SETTINGS.MKTO.QSAPPEND.SETTINGS.TITLE"
                          })}
                        </span>
                      </h2>
                    </div>
                    <div className="card-body p-3">
                      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
                          return (
                            <FormWrapper
                              {...{
                                errors,
                                touched,
                                values,
                                setFieldValue,
                                isSubmitting
                              }}
                            />
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card mb-5 mb-xxl-8">
                    <div className="card-header">
                      <h2 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3">
                          {intl.formatMessage({
                            id: "SETTINGS.MKTO.QSAPPEND.WEBHOOKURL.TITLE"
                          })}
                        </span>
                      </h2>
                    </div>
                    <div className="card-body p-3">
                      <MktoQSAppenderWebhookUrl />
                    </div>
                  </div>

                  <div className="card mb-5 mb-xxl-8">
                    <div className="card-header">
                      <h2 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3">
                          {intl.formatMessage({
                            id: "SETTINGS.MKTO.QSAPPEND.EMAILUPDATE.TITLE"
                          })}
                        </span>
                      </h2>
                    </div>
                    <div className="card-body p-3">
                      <MktoSQAppenderForceUpdate />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <InsSpinner />
          </div>
        )
      ) : (
        <div className="mt-10 mb-10">
          <Container>
            <div className="d-flex justify-content-center">
              <h3>
                {intl.formatMessage({
                  id: "SETTINGS.MKTO.GENERAL.DATAFETCHING.WAITMESSAGE"
                })}
              </h3>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default MktoQSAppenderSettings;
