import { useEffect, useRef } from 'react';
import {ThemeModeComponent, Mode} from '../../../../assets/ts/layout'
import { useIntl } from "react-intl";


const ThemeModeMenu = () => {
 const intl = useIntl();
    const menuElementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const menuElement = menuElementRef.current;
        const handleMenu = (): void => {
            if(menuElement) {
                menuElement.querySelectorAll<HTMLElement>('[data-kt-element="mode"]')
                ?.forEach((item: HTMLElement) => {
                  item.addEventListener('click', (e) => {
                    e.preventDefault()
          
                    const menuMode: string | null = item.getAttribute('data-kt-value')
                    const mode = menuMode === 'system' ? ThemeModeComponent.getSystemMode() : menuMode
          
                    if (mode) {
                      ThemeModeComponent.setMode(mode as Mode, menuMode as Mode | '')
                    }
                  })
                })
            }
          }
          if (menuElementRef.current) {
            handleMenu();
          }
      }, []);

    return (
        <div ref={menuElementRef} className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px" data-kt-menu="true" data-kt-element="theme-mode-menu">
        {/* begin::Menu item */}
        <div className="menu-item px-3 my-0">
            <a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="light">
                <span className="menu-icon" data-kt-element="icon">
                    <i className="ki-duotone ki-night-day fs-2"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span></i>            </span>
                <span className="menu-title">
                    {intl.formatMessage({ id: "MODE.LIGHT" })}
                </span>
            </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className="menu-item px-3 my-0">
            <a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
                <span className="menu-icon" data-kt-element="icon">
                    <i className="ki-duotone ki-moon fs-2"><span className="path1"></span><span className="path2"></span></i>            </span>
                <span className="menu-title">
                    {intl.formatMessage({ id: "MODE.DARK" })}
                </span>
            </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className="menu-item px-3 my-0">
            <a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="system">
                <span className="menu-icon" data-kt-element="icon">
                    <i className="ki-duotone ki-screen fs-2"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>            </span>
                <span className="menu-title">
                    {intl.formatMessage({ id: "MODE.SYSTEM" })}
                </span>
            </a>
        </div>
        {/* end::Menu item */}
        </div>
    )
  }
  
  export {ThemeModeMenu}