import { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import "../style/TimezoneSelect.css";
import ReactSelect, { createFilter } from "react-select";
import moment from "moment-timezone";
import { useIntl } from "react-intl";

import Button from "./Button";
import DropdownIndicator from "./DropdownIndicator";
import Option from "./Option";
import Dropdown from "./Dropdown";
import Group from "./Group";
import { getOptionFromValue, selectStyles, compareTimezoneGroups, has } from "../utils/utils";
import timezones from "../data/timezones.json";
import zoneToCC from "../data/zoneToCC.json";
import CCToCountryName from "../data/CCToCountryName.json";

export const TimezoneSelect = ({ value, onChange, ariaLabelledBy }) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const timezonesGroupedByCC = useMemo(() => {
    return Object.entries(timezones).reduce((output, [, timezoneData]) => {
      if (has(zoneToCC, timezoneData.id)) {
        const CC = zoneToCC[timezoneData.id];

        output[CC] = !output[CC] ? [] : output[CC];
        output[CC].push(timezoneData);
      }
      return output;
    }, {});
  }, []);

  const timezoneGroups = useMemo(() => {
    const groups = Object.entries(timezonesGroupedByCC)
      .reduce((output, [CC, zonesByCountry]) => {
        output.push({
          text: CCToCountryName[CC] + ": ",
          country: CCToCountryName[CC].toLowerCase().replace(" ", "-"),
          children: zonesByCountry,
          firstNOffset: zonesByCountry[0].nOffset
        });
        return output;
      }, [])
      .sort(compareTimezoneGroups);

    const guessedTimezoneName = moment.tz.guess();
    if (guessedTimezoneName) {
      const guessedTimezone = timezones[guessedTimezoneName];
      if (guessedTimezone) {
        groups.splice(0, 0, {
          text: "Local:",
          children: [guessedTimezone],
          firstNOffset: guessedTimezone.nOffset,
          firstOffset: guessedTimezone.offset
        });
      }
    }
    return groups;
  }, [timezonesGroupedByCC]);

  const options = useMemo(() => {
    return timezoneGroups.reduce((output, timezoneGroup) => {
      const timezoneGroupOptions = timezoneGroup.children.map((timezone) => ({
        value: timezone.id.split(" ").join("_"),
        label: timezone.name,
        time: moment().tz(timezone.id.split(" ").join("_")).format("LT"),
        groupName: timezoneGroup.text
      }));

      output.push({
        label: timezoneGroup.text,
        options: timezoneGroupOptions
      });

      return output;
    }, []);
  }, [timezoneGroups]);

  const filter = useMemo(
    () =>
      createFilter({
        ignoreAccents: false,
        stringify: (option) => `${option.label} ${option.value} ${option.data.groupName}`
      }),
    []
  );

  const currentOption = useMemo(() => getOptionFromValue(options, value), [value, options]);
  const selectElement = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      selectElement?.current?.focus();
    });
  };

  const handleChange = (option) => {
    setIsOpen(false);
    onChange(option.value);
  };

  const handleNoOptions = () => <span>No timezones found.</span>;

  return (
    <div className="tz-component">
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <Button isSelected={isOpen} onClick={toggleOpen}>
            {currentOption
              ? currentOption.label
              : intl.formatMessage({ id: "TIMEZONES.SEARCH", defaultMessage: "Search Timezones..." })}
          </Button>
        }
      >
        <ReactSelect
          ref={selectElement}
          noOptionsMessage={handleNoOptions}
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null, Option, Group }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={handleChange}
          options={options}
          placeholder={intl.formatMessage({ id: "TIMEZONES.SEARCH", defaultMessage: "Search Timezones..." })}
          styles={selectStyles}
          tabSelectsValue={false}
          value={currentOption}
          classNamePrefix="react-select"
          filterOption={filter}
          aria-labelledby={ariaLabelledBy}
        />
      </Dropdown>
    </div>
  );
};

TimezoneSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  ariaLabelledBy: PropTypes.string
};

export default TimezoneSelect;
