/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { useThemeMode } from "../../../../layout/partials";
import { toAbsoluteUrl } from "../../../../helpers";
import { useVerification } from "../core/VerificationProvider";
import { auth } from "../../../../firebase";
import { applyActionCode } from "firebase/auth";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import { Link } from "react-router-dom";

const VerifyYourEmail = () => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const { mode } = useThemeMode();
  useEffect(() => {
    document.body.style.backgroundImage =
      mode === "dark"
        ? `url(${toAbsoluteUrl("/media/auth/bg4-dark.jpg")})`
        : `url(${toAbsoluteUrl("/media/auth/bg4.jpg")})`;
  }, [mode]);

  const verification = useVerification();

  const handleVerifyEmail = () => {
    applyActionCode(auth, verification.actionCode)
      .then((response) => {
        toast.success(
          "The Email address has been successfully verified",
          toastSettings("success")
        );
      })
      .catch((error) => {
        console.log("Error : ", error);
        toast.error(`Error : ${error}`, toastSettings("error"));
      })
      .finally(() => {
        setIsEmailVerified(true);
      });
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center p-10">
          <div className="card card-flush  w-lg-650px py-5">
            <div className="card-body">
              {isEmailVerified ? (
                <>
                  <h2>Your password has been set</h2>
                  <p>
                    Go back to the login page to sign in with your new password
                  </p>
                  <Link to="/auth/login" className="btn btn-primary">
                    Back to login
                  </Link>
                </>
              ) : (
                <div className="pb-10">
                  <h2>Verify you email address</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Repellat corporis architecto, omnis magnam labore voluptate
                    distinctio culpa atque odi
                  </p>
                  <div className="fs-2 fw-bold text-primary text-center my-10">
                    {verification.email}
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary text-center"
                      onClick={handleVerifyEmail}
                    >
                      Verify email address
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyYourEmail;
