import axios from "axios";
import { KTIcon } from "../../../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { useInsJSFilesRequest } from "../core/InsJSFilesProvider";

const MySwal = withReactContent(Swal);

const ActionsDelete = (props: any) => {
  const { setRefetch } = useInsJSFilesRequest();
  const intl = useIntl();

  const handleOnClick = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "INSENTRICJS.DELETE.TITLE" }),
      text: intl.formatMessage(
        { id: "INSENTRICJS.DELETE.DESC" },
        { id: props.jsFileId }
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({
        id: "INSENTRICJS.DELETE.CONFIRM"
      }),
      cancelButtonText: intl.formatMessage({ id: "BUTTON.CANCEL" })
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/insentric-js/${props.jsFileId}`)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              MySwal.fire(
                intl.formatMessage({ id: "DELETE.SUCCESS.TITLE" }),
                intl.formatMessage(
                  { id: "DELETE.SUCCESS.TEXT" },
                  { id: props.jsFileId }
                ),
                "success"
              );
            } else {
              const error: any = `Error : ${response.status} - ${response.statusText}`;
              MySwal.fire(
                intl.formatMessage({ id: "DELETE.ERROR.TITLE" }),
                intl.formatMessage({ id: "DELETE.ERROR.TEXT" }),
                "error"
              );
            }
          })
          .catch((error: any) => {
            MySwal.fire(
              intl.formatMessage({ id: "DELETE.ERROR.TITLE" }),
              intl.formatMessage({ id: "DELETE.ERROR.TEXT" }),
              "error"
            );
          })
          .finally(() => {
            setRefetch(true);
          });
      }
    });
  };

  return (
    <div className="menu-item px-3">
      <button
        className="menu-link px-3"
        onClick={handleOnClick}
        data-tooltip-id={`deleteInsJSFile-${props.jsFileId}`}
        aria-label={intl.formatMessage({ id: "INSENTRICJS.DELETE" })}
      >
        <KTIcon iconName="trash" className="fs-2 m-0" />
      </button>
      <Tooltip
        id={`deleteInsJSFile-${props.jsFileId}`}
        key={`deleteInsJSFile-${props.jsFileId}`}
        content={intl.formatMessage({ id: "INSENTRICJS.DELETE" })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsDelete;
