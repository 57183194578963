import { UserModel, Permissions } from "../pages/auth/core/_models";

// Utility function to check for specific permission in permission sets
const hasPermission = (user: UserModel, permissionKey: keyof Permissions): boolean => {
  if (!user.roles) {
    return false;
  }

  const hasPermission = user.roles.some(role => {
    if (role.permissions_set) {
      const permissions = role.permissions_set as Permissions;
      const permissionValue = permissions[permissionKey];
      return permissionValue === true;
    } else {
      return false;
    }
  });

  console.log(`${permissionKey} permission:`, hasPermission);
  return hasPermission;
};

export const hasManageLimitsPermission = (user: UserModel): boolean => {
  return hasPermission(user, 'manage_limits');
};

export const hasManagePropertiesPermission = (user: UserModel): boolean => {
  return hasPermission(user, 'manage_properties');
};

export const hasManageSecretsPermission = (user: UserModel): boolean => {
  return hasPermission(user, 'manage_secrets');
};

export const hasManageUsersPermission = (user: UserModel): boolean => {
  return hasPermission(user, 'manage_users');
};
