import { FC } from "react";
import { useIntl } from "react-intl";
import ActionsDelete from "./QueryBuilderDeleteQueryAction";

type Props = {
  fetchedQueriesData: any;
  tableReload: () => void;
};

const QueryBuilderSavedTable: FC<Props> = ({ fetchedQueriesData, tableReload }) => {
  const intl = useIntl();

  return (
    <table className="table table-striped table-hover">
      <thead className="table-light border-bottom">
        <tr className="fw-bold fs-7 text-dark border-gray-600 py-4">
          <th scope="col" className="text-center" style={{ minWidth: "100px" }}>
            {intl.formatMessage({ id: "QUERYBUILDER.TABLE.OBJECT" })}
          </th>
          <th scope="col" className="text-center">
            {intl.formatMessage({ id: "QUERYBUILDER.TABLE.QUERIES" })}
          </th>
          <th scope="col" className="text-center">
            {intl.formatMessage({ id: "QUERYBUILDER.TABLE.DELETE" })}
          </th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {Object.keys(fetchedQueriesData).length !== 0 ? (
          fetchedQueriesData.map((query: any, index: number) => {
            if (query.query !== "") {
              return (
                <tr key={index} className="border-bottom align-middle">
                  <td className="ps-2 text-capitalize">{query.object}</td>
                  <td className="ps-2 font-monospace">{query.query}</td>
                  <td>
                    <ActionsDelete object={query.object} tableReload={tableReload} />
                  </td>
                </tr>
              );
            }
          })
        ) : (
          <tr>
            <td colSpan={3} className="text-center h-100px align-items-center">
              {intl.formatMessage({ id: "QUERYBUILDER.TABLE.NOQUERIES" })}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default QueryBuilderSavedTable;
