import { Formik, Form as FormikForm } from 'formik';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

export default function Form({ fields, initialValues, validationSchema, handleSubmit }) {
  const intl = useIntl();
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema ? validationSchema : undefined}
      onSubmit={handleSubmit}
    >
      {({ touched, errors, isSubmitting }) => (
        <FormikForm className='form'>
          {fields.map(({ label, id, description, optional, options, fieldComponent: FieldComponent, showRefreshButton, refreshType, disabled }) => (
            <FieldComponent
              key={id}
              id={id}
              label={label}
              description={description}
              options={options}
              optional={optional}
              isInvalid={touched[id] && errors[id]}
              showRefreshButton={showRefreshButton}
              refreshType={showRefreshButton ? refreshType : undefined}  // Pass the refreshType only if showRefreshButton is true
              disabled={disabled}
            />
          ))}

          <Button style={{marginTop: 20, marginBottom : 10}} type='submit' variant='primary' disabled={isSubmitting}>
            {isSubmitting ? intl.formatMessage({ id : 'FORM.SUBMITTING'}) : intl.formatMessage({ id: 'FORM.SUBMIT' })}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
}

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    optional: PropTypes.bool,
    options: PropTypes.array,
    fieldComponent: PropTypes.elementType.isRequired,
    showRefreshButton: PropTypes.bool,
    refreshType: (props, propName, componentName) => {
      if (props.showRefreshButton && !props[propName]) {
        return new Error(
          `${propName} is required when showRefreshButton is true in ${componentName}`
        );
      }
      return null;
    },
  })).isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

