import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";

const ShowPasswordIcon = ({
  id,
  showPassword,
  setShowPassword
}: {
  id: string;
  showPassword: boolean;
  setShowPassword: () => void;
}) => {
  return (
    <div
      id={id}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
      onClick={setShowPassword}
      data-tooltip-id="showHidePassword"
    >
      <KTIcon
        iconName={showPassword ? "eye-slash" : "eye"}
        iconType="outline"
        className="iconBtn fs-1"
      />
      <Tooltip
        id="showHidePassword"
        key="showHidePassword"
        content={showPassword ? "Hide Password" : "Show Password"}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};
export { ShowPasswordIcon };
