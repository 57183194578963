import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../../helpers";

const CopyToClipboardIcon = ({
  handleCopyPassword,
  copied
}: {
  handleCopyPassword: any;
  copied: boolean;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        right: "50px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
      onClick={handleCopyPassword}
    >
      {copied ? (
        <KTIcon iconName="check-square" iconType="outline" className="fs-1 text-success" />
      ) : (
        <div data-tooltip-id="copyToClipboard">
          <KTIcon iconName="copy" iconType="outline" className="iconBtn fs-1" />
          <Tooltip
            id="copyToClipboard"
            key="copyToClipboard"
            content="Copy to Clipboard"
            place="top"
            variant="dark"
            style={{ zIndex: 100 }}
          />
        </div>
      )}
    </div>
  );
};
export { CopyToClipboardIcon };
