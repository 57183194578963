import { object, string, ref } from "yup";
import { forbiddenWords } from "../../user-verification/core/_forbiddenWords";

const specialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const passwordValidationSchema = object({
  password: string()
    .required("Password is required.")
    // check minimum characters
    .min(8, "Password should be at least 8 characters.")
    // different error messages for different requirements
    .matches(/[0-9]/, "Password should contain a digit.")
    .matches(/[a-z]/, "Password should contain a lowercase letter.")
    .matches(/[A-Z]/, "Password should contain an uppercase letter.")
    .matches(specialChar, "Password should contain a special character.")
    .test({
      message: () => "Password should not contain spaces.",
      test: (value) => !/\s/.test(value)
    })
    .test({
      message: () => "Password should not contain forbidden words.",
      test: (value) => {
        const forbidden = forbiddenWords.some(
          (word) => value.toLocaleLowerCase() === word.toLocaleLowerCase()
        );
        return !forbidden;
      }
    }),
  confirmPassword: string().test({
    message: "Passwords do not match.",
    test: function (value) {
      return value === this.parent.password;
    }
  })
});
