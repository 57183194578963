import { Form, Formik } from "formik";
import PasswordFields from "./PasswordFields";
import PasswordRequirements from "./PasswordRequirements";
import { passwordValidationSchema } from "../schema/passwordValidationSchema";
import { useState } from "react";
import { updatePassword } from "firebase/auth";
import { useAuth } from "../../../auth";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import { auth } from "../../../../firebase";

const PasswordForm = () => {
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isNotEmpty: false,
    isNotForbidden: true,
    isLongEnough: false,
    isMatching: false
  });

  const onSubmit = (values: any) => {
    if (auth.currentUser) {
      updatePassword(auth.currentUser, values.password)
        .then((response) => {
          toast.success("The Password has been successfully updated", toastSettings("success"));
        })
        .catch((error) => {
          console.error("Error :", error);
          toast.error(
            "The Passwor has not been updated. Please log out, log in and try again.",
            toastSettings("error")
          );
        });
    }
  };

  return (
    <>
      <div className="pb-10">
        {" "}
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat corporis architecto, omnis magnam labore
          voluptate distinctio culpa atque odi
        </p>
      </div>
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={passwordValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, touched, errors, setFieldValue }) => {
          return (
            <Form id="set-password">
              <PasswordFields
                setPasswordRequirements={setPasswordRequirements}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
              />
            </Form>
          );
        }}
      </Formik>
      <PasswordRequirements requirements={passwordRequirements} />
    </>
  );
};
export default PasswordForm;
