import { createContext, FC, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { initialVerificationContext, verificationContextProps, WithChildren } from "./_model";
import { auth } from "../../../../firebase";
import { checkActionCode } from "firebase/auth";
import { deleteAllQueryParams, getParameterByName } from "./_helper";

const UserVerificationContext = createContext<verificationContextProps>(initialVerificationContext);

const VerificationProvider: FC<WithChildren> = ({ children }: any) => {
  const params = useMemo(() => {
    return {
      mode: getParameterByName("mode"),
      actionCode: getParameterByName("oobCode"),
      continueUrl: getParameterByName("continueUrl"),
      lang: getParameterByName("lang") || "en"
    };
  }, []);

  const [actionCodeCheckResponse, setActionCodeCheckResponse] = useState<any>({
    emailVerified: false,
    multiFactorInfo: "",
    previousEmail: "",
    isActionCodeExpired: false,
    email: "",
    operation: "",
    isFetching: true
  });

  const checkActionCodeHandler = useCallback(() => {
    checkActionCode(auth, params.actionCode)
      .then((response) => {
        setActionCodeCheckResponse((prevState: any) => {
          return {
            ...prevState,
            email: response.data.email,
            operation: response.operation,
            multiFactorInfo: response.data.multiFactorInfo,
            previousEmail: response.data.previousEmail,
            isActionCodeVerified: true,
            isActionCodeExpired: false
          };
        });
      })
      .catch((error) => {
        // Invalid code.
        console.error("Error while verifying the code : ", error.code);
        if (error.code === "auth/invalid-action-code") {
          setActionCodeCheckResponse((prevState: any) => {
            return {
              ...prevState,
              isActionCodeVerified: false
            };
          });
          // Expired code.
        } else if (error.code === "auth/expired-action-code") {
          setActionCodeCheckResponse((prevState: any) => {
            return {
              ...prevState,
              isActionCodeExpired: true
            };
          });
        }
      })
      .finally(() => {
        setActionCodeCheckResponse((prevState: any) => {
          return {
            ...prevState,
            isFetching: false
          };
        });
        deleteAllQueryParams();
      });
  }, [params.actionCode]);

  useEffect(() => {
    checkActionCodeHandler();
  }, [checkActionCodeHandler]);

  return (
    <UserVerificationContext.Provider
      value={{
        mode: params.mode,
        actionCode: params.actionCode,
        continueUrl: params.continueUrl,
        lang: params.lang,
        email: actionCodeCheckResponse?.email || "",
        operation: actionCodeCheckResponse?.operation || "",
        multiFactorInfo: actionCodeCheckResponse?.multiFactorInfo || "",
        previousEmail: actionCodeCheckResponse?.previousEmail || "",
        isActionCodeVerified: actionCodeCheckResponse?.isActionCodeVerified || false,
        isActionCodeExpired: actionCodeCheckResponse?.isActionCodeExpired || false,
        isFetching: actionCodeCheckResponse?.isFetching
      }}
    >
      {children}
    </UserVerificationContext.Provider>
  );
};

const useVerification = () => useContext(UserVerificationContext);

export { VerificationProvider, useVerification };
