/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";

type Props = {
  tab: string;
};

const Roles: React.FC<Props> = ({ tab }) => {
  return (
    <>
      <div className={clsx("tab-pane", { active: tab === "Roles" })}>
        <div className="separator separator-dashed my-6"></div>
      </div>
    </>
  );
};

export default Roles;
