import { useIntl } from "react-intl";
import { MultiSelectDropdownWithInput } from "../../../../../layout/components/form/FormFields";

const FormFieldParameter = (props: any) => {
  const intl = useIntl();
  return (
    <div className="m-3">
      <span className="fs-5 fw-bold" id="url-params-label">
        {intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.URLPARAMETERS" })}
      </span>
      <div className="text-muted mb-3" id="url-params-description">
        {intl.formatMessage({
          id: "SETTINGS.MKTO.QSAPPEND.URLPARAMETERS.DESC"
        })}
      </div>
      <MultiSelectDropdownWithInput
        id="mkto_qs_appender_url_parameters"
        label={intl.formatMessage({ id: "SETTINGS.MKTO.QSAPPEND.URLPARAMETERS" })}
        hideLabel={true}
        description=""
        ariaLabelledBy="url-params-label"
        ariaDescribedBy="url-params-description"
        hideDescription={true}
        options={[
          { label: "utm_source", value: "utm_source" },
          { label: "utm_medium", value: "utm_medium" },
          { label: "utm_campaign", value: "utm_campaign" },
          { label: "utm_content", value: "utm_content" },
          { label: "utm_term", value: "utm_term" }
        ]}
        classes={
          props.errors.mkto_qs_appender_url_parameters &&
          props.touched.mkto_qs_appender_url_parameters
            ? "border-danger"
            : ""
        }
        showRefreshButton={false}
      />
      {!!props.errors.mkto_qs_appender_url_parameters &&
      props.touched.mkto_qs_appender_url_parameters ? (
        <p className="text-danger">
          {intl.formatMessage({
            id: props.errors.mkto_qs_appender_url_parameters
          })}
        </p>
      ) : null}
    </div>
  );
};

export default FormFieldParameter;
