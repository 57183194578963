import React from "react";
import { useIntl } from "react-intl";
import CompanyImageCropper from "./CompanyImageCropper";

interface CompanyCropperModalProps {
  imageSrc: any;
  handleCroppedImage: any;
  theme: string;
}

const CompanyCropperModal: React.FC<CompanyCropperModalProps> = ({ imageSrc, handleCroppedImage, theme }) => {
  const intl = useIntl();

  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id={theme === "light" ? "modal_crop_image_light" : "modal_crop_image_dark"}
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content ${theme === "dark" ? "bg-primary" : ""}`}>
          <div className="modal-header">
            <h2 className="fw-bold">
              {intl.formatMessage({ id: "SETTINGS.ACCOUNT.ACCOUNTSETTINGS.CROPIMAGE", defaultMessage: "Crop Image" })}
            </h2>
            <div
              className="btn btn-icon btn-sm btn-active-icon-primary"
              data-bs-dismiss="modal"
            >
              <i className="ki-duotone ki-cross fs-3x">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>

          <div className="modal-body">
            <CompanyImageCropper imgSrc={imageSrc} handleCroppedImage={handleCroppedImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCropperModal;
