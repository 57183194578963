/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { KTIcon } from '../../../helpers';

const SidebarFooter: React.FC = () => {
  const intl = useIntl();
  const [reportUrl, setReportUrl] = useState<string>("");

  useEffect(() => {
    const fetchReportUrl = async () => {
      try {
        const response = await axios.get('/reports');
        if (response.data && response.data.length > 0) {
          setReportUrl(response.data[0].url);
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };

    if (reportUrl === "") {
      fetchReportUrl();
    }
  }, [reportUrl]);

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        href={reportUrl}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss='click'
        title={intl.formatMessage({ id: "SIDEBAR.BUTTON.GOTOREPORTS" })}
      >
        <span className='btn-label'>{intl.formatMessage({ id: "SIDEBAR.BUTTON.GOTOREPORTS" })}</span>
        <KTIcon iconName='chart-simple-2' className='btn-icon fs-2 m-0 p-0' />
      </a>
    </div>
  );
};

export { SidebarFooter };
