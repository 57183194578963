import { Tooltip } from "react-tooltip";
import { KTIcon } from "../../../../helpers";
import { useInsJSFilesRequest } from "../core/InsJSFilesProvider";
import { useIntl } from "react-intl";

const ActionsUpdate = (props: any) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useInsJSFilesRequest();
  const intl = useIntl();

  const openEditModal = () => {
    console.log("Update JS file with ID: ", props.jsFileId);
    setItemIdForUpdate((prev) => props.jsFileId);
    console.log("Item Id for Update: ", itemIdForUpdate);
  };

  return (
    <div className="menu-item px-3">
      <button
        className="menu-link px-3"
        onClick={openEditModal}
        data-tooltip-id={`updateInsJSFile-${props.jsFileId}`}
        aria-label={intl.formatMessage({ id: "INSENTRICJS.EDIT" })}
      >
        <KTIcon iconName="pencil" className="fs-2 m-0" />
      </button>
      <Tooltip
        id={`updateInsJSFile-${props.jsFileId}`}
        key={`updateInsJSFile-${props.jsFileId}`}
        content={intl.formatMessage({ id: "INSENTRICJS.EDIT" })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsUpdate;
